import React from 'react';
import PropTypes from 'prop-types';

const TailWindButton = (props) => (
  <a 
  className={`bg-primary text-white px-3 py-2 rounded-md text-base drop-shadow-md transition duration-300 hover:bg-accent ${props.extraClasses}`}
  href={props.href}>
    {props.children}
  </a>
);

TailWindButton.propTypes = {
  href: PropTypes.string,
  children: PropTypes.string,
  extraClasses: PropTypes.string
};

TailWindButton.defaultProps = {
  href: '#',
  children: 'Click here',
  extraClasses: ''
};

export default TailWindButton;
