import React from 'react';
const RoadMap = () => (
  <section className='container m-auto'>
    <article className='grid grid-cols-2 gap-4 my-3'>
      <div className='grid justify-items-center'>
        <img 
          src={`${process.env.PUBLIC_URL}/assets/images/roadmap.webp`}
          alt='clipart of a person with lots of documents'
        />
      </div>
      <div className='grid gap-4 py-6 font-medium'>
        <h1 className="text-4xl text-black">
          Looking for more features
        </h1>

        <p className='text-gray-500 my-5'>
          We are here to listen because we were at some point in your shoe, reach out to us and ask for new features and check out regularly for our updated app.
        </p>
      </div>
    </article>
  </section>
);

export default RoadMap;
