import Header from './components/Header/Header';
import WhyCvdb from './components/WhyCVDB/WhyCVDB';
import DigitalSolutions from './components/DigitalSolutions/DigitalSolutions';
import Counters from './components/Counters/Counters';
import RoadMap from './components/RoadMap/RoadMap';

function App() {
  return (
    <main>
      <Header />
      <WhyCvdb />
      <DigitalSolutions/>
      <Counters />
      <RoadMap />
    </main>
  );
}

export default App;
