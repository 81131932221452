import React from 'react';

const whyCVDBList = [
  {
    img: `${process.env.PUBLIC_URL}/assets/images/icon-G_S.1.webp`,
    label: 'Build your full resume only 1 time with all of your experience'
  },{
    img: `${process.env.PUBLIC_URL}/assets/images/icon-G_S.2.webp`,
    label: 'Automate the process of creating resume every time'
  },{
    img: `${process.env.PUBLIC_URL}/assets/images/icon-G_S.3.webp`,
    label: 'Send tailored resume for your dream job in seconds'
  },{
    img: `${process.env.PUBLIC_URL}/assets/images/icon-G_S.4.webp`,
    label: 'Track the process of each interview in a convenient way'
  },
]

console.log(process.env)
const WhyCvdb = () => (
  <section className='container m-auto'>
    <article className='grid grid-cols-4 grid-gap-3 text-center'>
      {whyCVDBList.map((item, index) => <div key={index}><img className='m-auto mb-4 grayscale hover:grayscale-0 transition duration-300' width={80} src={item.img} alt={item.label} />{item.label}</div>)}
    </article>
  </section>
);
export default WhyCvdb;
