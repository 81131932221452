import React from 'react';
import NumberAnimated from '../NumberAnimated/NumberAnimated';
import styles from './Counters.module.scss';

const counters = [
  {
    label: 'Users',
    number: 1000,
  },
  {
    label: 'Interviews',
    number: 24000,
  },
  {
    label: 'Processes tracked',
    number: 1000000,
  },
]

const Counters = () => (
  <section className='bg-gray-200 py-6'>
    <article className='py-6 container m-auto'>
      <article className='grid grid-cols-3 grid-gap-3 text-center'>
        {counters.map((item, index) => <div key={index}><section className={`text-primary text-4xl font-thin ${styles.counterNumber}`}><NumberAnimated number={item.number} /></section><section className='font-light'>{item.label}</section></div>)}
      </article>
    </article>
  </section>
);

export default Counters;
