import React from 'react';
import TailWindButton from '../TailWind/Button/Button';

const Header = () => (
  <section className='container m-auto'>
    <article className='grid grid-cols-2 gap-4 my-3'>
      <div className='grid gap-4 py-6 font-medium'>
        <h1 className="text-4xl text-black">
          Tired from creating dozens of resumes every day
        </h1>

        <p className='text-gray-500 my-5'>
          Are you tired of creating from scratch new resume every time for applying for a new job. 
        </p>


        <p className='text-gray-500 my-5'>
          Luckily now we have CVDB. an easy automated way to generate dozens of emails tailored for your dream job.
        </p>

        <div>
          <TailWindButton
          href={'http://google.com'}>
            Download now
          </TailWindButton>
        </div>
      </div>
      <div className='grid justify-items-center'>
        <img 
          src={`${process.env.PUBLIC_URL}/assets/images/person-with-lots-of-documents.webp`}
          alt='clipart of a person with lots of documents'
        />
      </div>
    </article>
  </section>

);

export default Header;
