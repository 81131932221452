import React from 'react';
import TailWindButton from '../TailWind/Button/Button';

const DigitalSolutions = () => (
  <section class="bg-gradient-to-r from-indigo-200 to-indigo-800 py-6 text-center text-white mt-6">
    <h1 className='text-4xl py-6'>Start in a smart way today</h1>

    <p className='text-white my-5'>
      Create your profile today once, and save yourself thousands of hours customizing it each time!
    </p>

    <TailWindButton 
    extraClasses={'bg-white text-primary rounded-lg px-6 py-3 hover:bg-secondary hover:text-white inline-block'}
    href={'https://google.com'}
    >
      Start today
    </TailWindButton>
  </section>
);

export default DigitalSolutions;
