import React from 'react';
import PropTypes from 'prop-types';
import {useSpring , animated} from 'react-spring';

const NumberAnimated = (props) => {
  const { number } = useSpring({
    from: {number: 0},
    number: props.number,
    delay: 200,
    config: {
      mass: 1, 
      tension: 20,
      friction: 10
    }
  })

  return <animated.div>{number.to(n => n.toFixed(0))}</animated.div>
};

NumberAnimated.propTypes = {
  number: PropTypes.number
};

NumberAnimated.defaultProps = {
  number: 100
};

export default NumberAnimated;
